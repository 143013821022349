import type {
  Local,
  IntUser,
  IntUserResponse,
} from './types';
import type { ChoiceAsDefault as NewDocumentChoiceAsDefault } from 'pages/NewDocument/types';
import type { AppropriateTextSaved } from 'pages/ECommerceCreate/types';
import type { ComparableDocument, IntDocumentRow } from 'pages/MyDocuments/types';
import noop from 'functions/general/noop';
import { AnalyzedDocument } from 'pages/WebCheckerMultiple/types';
import { WebCheckerField } from 'pages/WebCheckerECommerce/types';

const defaultLocal: Local = {
  auth: {
    getUser: () => undefined,
    setUser: (_user: IntUserResponse) => {
      // This is intentional
    },
    delUser: noop,
    setUserUser: (_user: IntUser) => {
      // This is intentional
    },
    isPremium: () => true,
    isLoggedIn: () => true,
    hasSubscription: () => true,
    setHasSubscription: (_value: boolean) => {
      // This is intentional
    },
    getMaxChars: () => 0,
    getMaxPDFSize: () => 0,
    isAdmin: () => true,
    isPrepaid: () => true,
    isTrial: () => true,
    isPersonal: () => true,
    isBusiness: () => true,
    hasLicence: () => true,
    canShare: () => true,
    hasOnlyLicence: () => true,
    hasMyDocuments: () => true,
    removeOneCredit: () => {
      // This is intentional
    },
    updateCredits: (_credits: number) => {
      // This is intentional
    }
  },
  cookies: {
    getLocalStorageCookies: () => true,
    setLocalStorageCookies: noop
  },
  analysis: {
    getLastFullReportId: () => '',
    setLastFullReportId: (_id: string) => {
      // This is intentional
    },
    delLastFullReportId: noop
  },
  firstTime: {
    getLocalStorageFirstTime: () => true,
    setLocalStorageFirstTime: noop
  },
  tutorials: {
    setTutorialIsDone: (_tutorial: string) => {
      // This is intentional
    },
    getTutorials: () => [],
    clearTutorials: noop
  },
  touchPointsZoom: {
    setTouchPointZoom: (_rootId: string, _size: { width: number; height: number }) => {
      // This is intentional
    },
    getTouchPointZoom: (_rootId: string) => null
  },
  wordCloud: {
    getLocalStorageWordCloud: (_documentId: string) => [],
    setLocalStorageWordCloud: (_data: string, _documentId: string) => {
      // This is intentional
    },
    delLocalStorageWordCloud: (_documentId: string) => {
      // This is intentional
    }
  },
  cardStates: {
    getLocalStorageCardState: (_id: string, _defaultValue: boolean) => true,
    setLocalStorageCardState: (_id: string, _isOpen: boolean) => {
      // This is intentional
    }
  },
  ga4Email: {
    getLocalStorageGA4Email: () => '',
    setLocalStorageGA4Email: (_email: string) => {
      // This is intentional
    }
  },
  metaEmail: {
    getLocalStorageMetaEmail: () => '',
    setLocalStorageMetaEmail: (_email: string) => {
      // This is intentional
    }
  },
  preferences: {
    addOne: (_key: string, _value: any) => {
      // This is intentional
    },
    getOne: (_key: string) => {
      // This is intentional
    },
    SUGGESTION_FLAG: 'SUGGESTION_FLAG'
  },
  productSaved: {
    getLocalStorageProductSaved: () => [],
    setLocalStorageProductSaved: (_data: AppropriateTextSaved[]) => {
      // This is intentional
    },
    delLocalStorageProductSaved: noop
  },
  eCommerceFields: {
    getLocalStorageECommerceFields: (_domain: string) => [],
    setLocalStorageECommerceFields: (_domain: string, _data: WebCheckerField[]) => {
      // This is intentional
    },
    delLocalStorageECommerceFields: (_domain: string) => {
      // This is intentional
    }
  },
  summaryOrder: {
    getLocalStorageSummaryOrder: () => [],
    setLocalStorageSummaryOrder: (_data: string[]) => {
      // This is intentional
    },
    delLocalStorageSummaryOrder: noop
  },
  leftMenuState: {
    getLocalStorageLeftMenuState: () => true,
    setLocalStorageLeftMenuState: (_isClosed: boolean) => {
      // This is intentional
    }
  },
  mobileWarning: {
    getLocalStorageMobileWarning: () => true,
    setLocalStorageMobileWarning: noop
  },
  choiceAsDefault: {
    getLocalStorageChoiceAsDefault: () => undefined,
    setLocalStorageChoiceAsDefault: (_data: NewDocumentChoiceAsDefault) => {
      // This is intentional
    },
    delLocalStorageChoiceAsDefault: noop
  },
  compareDocument: {
    getCompareDocument: () => undefined,
    setCompareDocument: (_data: ComparableDocument | undefined) => {
      // This is intentional
    },
    delCompareDocument: noop
  },
  uxFeaturesOrder: {
    getLocalStorageUxFeaturesOrder: () => [],
    setLocalStorageUxFeaturesOrder: (_data: string[]) => {
      // This is intentional
    },
    delLocalStorageUxFeaturesOrder: noop
  },
  selectedDocuments: {
    get: () => [],
    set: (_data: IntDocumentRow) => {
      // This is intentional
    },
    remove: noop
  },
  wordCloudSearched: {
    get: (_id: string, _defaultValue: boolean) => true,
    set: (_id: string, _isOpen: boolean) => {
      // This is intentional
    }
  },
  notEditableWarning: {
    getLocalStorageNotEditableWarning: () => true,
    setLocalStorageNotEditableWarning: noop
  },
  customerMetricsOrder: {
    getLocalStorageCustomerMetricsOrder: () => [],
    setLocalStorageCustomerMetricsOrder: (_data: string[]) => {
      // This is intentional
    },
    delLocalStorageCustomerMetricsOrder: noop
  },
  webCheckerDocuments: {
    getLocalStorageWebCheckerDocuments: (_id: string) => null,
    setLocalStorageWebCheckerDocuments: (_url: string, _data: AnalyzedDocument) => {
      // This is intentional
    },
    delLocalStorageWebCheckerDocuments: noop
  },
  webCheckerEcommerceDetails: {
    getLocalStorageWebCheckerEcommerceDetails: (_url: string) => null,
    setLocalStorageWebCheckerEcommerceDetails: (_url: string, _id: string) => {
      // This is intentional
    },
    delLocalStorageWebCheckerEcommerceDetails: noop
  }
};

export default defaultLocal;
