import { Suspense, lazy, type FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import type { ProtectorProps } from 'routes/ProtectorProduction';

const AccountSettings = lazy(() => import('pages/AccountSettings'));
const Admin = lazy(() => import('pages/Admin'));
const CookiePolicy = lazy(() => import('pages/CookiePolicy'));
const Faq = lazy(() => import('pages/Faq'));
const Glossary = lazy(() => import('pages/Glossary'));
const HowTo = lazy(() => import('pages/HowTo'));
const NotFound = lazy(() => import('pages/NotFound'));
const Payments = lazy(() => import('pages/Payments'));
const BillingOwner = lazy(() => import('pages/Payments/BillingOwner'));
const Invoices = lazy(() => import('pages/Payments/Invoices'));
const Licences = lazy(() => import('pages/Payments/Licences'));
const PaymentInfo = lazy(() => import('pages/Payments/PaymentInfo'));
const PricingPrice = lazy(() => import('pages/Payments/PricingPrice'));
const Privacy = lazy(() => import('pages/Privacy'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const Terms = lazy(() => import('pages/Terms'));
const ValidateEmail = lazy(() => import('pages/ValidateEmail'));
const Home = lazy(() => import('pages/Home'));
const Analysis = lazy(() => import('pages/Analysis'));
const NewDocument = lazy(() => import('pages/NewDocument'));
const MyDocuments = lazy(() => import('pages/MyDocuments'));
const WordExplorer = lazy(() => import('pages/WordExplorer'));
const WordExplorerPublic = lazy(() => import('pages/WordExplorerPublic'));
const Customize = lazy(() => import('pages/Customize'));
const ReadabilityPublic = lazy(() => import('pages/ReadabilityPublic'));
const PublicBrainPain = lazy(() => import('pages/PublicBrainPain'));
const PublicSentimentAnalysis = lazy(() => import('pages/PublicSentimentAnalysis'));
const Comparison = lazy(() => import('pages/Comparison'));
const Ux = lazy(() => import('pages/Ux'));
const ECommerce = lazy(() => import('pages/ECommerce'));
const NewECommerce = lazy(() => import('pages/NewECommerce'));
const ECommerceProduct = lazy(() => import('pages/ECommerceProduct'));
const MyECommerceDocuments = lazy(() => import('pages/MyECommerceDocuments'));
const ECommerceCreate = lazy(() => import('pages/ECommerceCreate'));
const WebCheckerMenu = lazy(() => import('pages/WebCheckerMenu'));
const WebChecker = lazy(() => import('pages/WebChecker'));
const WebCheckerMultiple = lazy(() => import('pages/WebCheckerMultiple'));
const WebCheckerMultipleResults = lazy(() => import('pages/WebCheckerMultiple/WebChecker'));
const WebCheckerECommerce = lazy(() => import('pages/WebCheckerECommerce'));
const GoogleAnalytics = lazy(() => import('pages/GoogleAnalytics'));
const NewECommerceShopify = lazy(() => import('pages/NewECommerceShopify'));
const Instagram = lazy(() => import('pages/Instagram'));

type Props = {
  Protector: FC<ProtectorProps>;
  Loader: FC;
}

const selectHome = (_domain: string) => {
  // if (domain === 'https://analytics.tigim.co') return GoogleAnalytics;
  return Home;
};

const selectHomeMeta = (_domain: string) => {
  // if (domain === 'https://analytics.tigim.co') return { };
  return { auth: true, notAdmin: true };
}

const TigimRoutes = ({ Protector, Loader }: Props) => {
  return (
  <Suspense fallback={<Loader />}>
    <Routes>
      <Route path="/account" element={<Protector name="AccountSettings" elem={AccountSettings} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/payments/pricing-plan" element={<Protector name="PricingPrice" elem={PricingPrice} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/payments/licences" element={<Protector name="Licences" elem={Licences} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/payments/billing" element={<Protector name="BillingOwner" elem={BillingOwner} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/payments/info" element={<Protector name="PaymentInfo" elem={PaymentInfo} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/payments/invoices" element={<Protector name="Invoices" elem={Invoices} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/payments" element={<Protector name="Payments" elem={Payments} meta={{ auth: true, notAdmin: true, payments: true }} />} />
      <Route path="/documents/:owner/:folder/:page" element={<Protector name="MyDocumentsFolder" elem={MyDocuments} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/documents/:owner/:page" element={<Protector name="MyDocuments" elem={MyDocuments} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/how-to/:page" element={<Protector name="HowTo" elem={HowTo} meta={{ auth: false, notAdmin: true }} />} />
      <Route path="/how-to" element={<Protector name="HowTo" elem={HowTo} meta={{ auth: false, notAdmin: true }} />} />
      <Route path="/glossary/:char/:page" element={<Protector name="Glossary" elem={Glossary} meta={{ auth: false }} />} />
      <Route path="/glossary/:char/" element={<Protector name="Glossary" elem={Glossary} meta={{ auth: false }} />} />
      <Route path="/faq/" element={<Protector name="Faq" elem={Faq} meta={{ auth: false }} />} />
      <Route path="/admin/:page/:id" element={<Protector name="Admin" elem={Admin} meta={{ auth: true, admin: true }} />} />
      <Route path="/admin/:page" element={<Protector name="Admin" elem={Admin} meta={{ auth: true, admin: true }} />} />
      <Route path="/email_confirmation/:token" element={<Protector name="ValidateEmail" elem={ValidateEmail} />} />
      <Route path="/reset-password/:token" element={<Protector name="ResetPassword" elem={ResetPassword} />} />
      <Route path="/privacy" element={<Protector name="Privacy" elem={Privacy} />} />
      <Route path="/terms" element={<Protector name="Terms" elem={Terms} />} />
      <Route path="/cookie-policy" element={<Protector name="CookiePolicy" elem={CookiePolicy} />} />
      <Route path="/word-explorer" element={<Protector name="WordExplorer" elem={WordExplorer} />} />
      <Route path="/word-explorer-public" element={<Protector name="WordExplorerPublic" elem={WordExplorerPublic} />} />
      <Route path="/readability-checker" element={<Protector name="ReadabilityPublic" elem={ReadabilityPublic} />} />
      <Route path="/sentiment-analysis" element={<Protector name="PublicSentimentAnalysis" elem={PublicSentimentAnalysis} />} />
      <Route path="/brain-pain" element={<Protector name="PublicBrainPain" elem={PublicBrainPain} />} />
      <Route path="/customize/:tab/:op/:id" element={<Protector name="Customize" elem={Customize} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/customize/:tab/:op/" element={<Protector name="Customize" elem={Customize} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/customize/:tab" element={<Protector name="Customize" elem={Customize} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/analysis/:id/:tab/:subtab" element={<Protector name="Analysis" elem={Analysis} />} />
      <Route path="/analysis/:id/:tab" element={<Protector name="Analysis" elem={Analysis} />} />
      <Route path="/analysis" element={<Protector name="NewDocument" elem={NewDocument} meta={{ notAdmin: true, licenced: true }} />} />
      <Route path="/login/:email" element={<Protector name="Login" elem={NewDocument} meta={{ auth: false }} />} />
      <Route path="/e-commerce/product" element={<Protector name="ECommerceCreate" elem={ECommerceCreate} />} />
      <Route path="/comparison/:doc1/:doc2" element={<Protector name="Comparison" elem={Comparison} />} />
      <Route path="/ux/:id" element={<Protector name="Ux" elem={Ux} />} />
      <Route path="/e-commerce/:doc/:region/:id/:tab" element={<Protector name="ECommerceProduct" elem={ECommerceProduct} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/e-commerce/:doc/:region/:page" element={<Protector name="ECommerce" elem={ECommerce} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/new-e-commerce" element={<Protector name="NewECommerce" elem={NewECommerce} />} />
      <Route path="/new-e-commerce-spf" element={<Protector name="NewECommerceShopify" elem={NewECommerceShopify} meta={{withOpenedSignIn: true}} />} />
      <Route path="/my-e-commerce-documents" element={<Protector name="MyECommerceDocuments" elem={MyECommerceDocuments} />} />
      <Route path="/web-checker/:tab" element={<Protector name="WebCheckerMenu" elem={WebCheckerMenu} />} />
      <Route path="/web-checker/simple/:address/:analysisId" element={<Protector name="WebChecker" elem={WebChecker} />} />
      <Route path="/web-checker/simple/:address" element={<Protector name="WebChecker" elem={WebChecker} />} />
      <Route path="/web-checker/e-commerce/:address/:analysisId" element={<Protector name="WebCheckerECommerce" elem={WebCheckerECommerce} />} />
      <Route path="/web-checker/e-commerce/:address" element={<Protector name="WebCheckerECommerce" elem={WebCheckerECommerce} />} />
      <Route path="/web-checker/multiple/:address/results" element={<Protector name="WebCheckerMultipleResults" elem={WebCheckerMultipleResults} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/web-checker/multiple/:address/:analysisId" element={<Protector name="WebCheckerMultiple" elem={WebCheckerMultiple} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/web-checker/multiple/:address" element={<Protector name="WebCheckerMultiple" elem={WebCheckerMultiple} meta={{ auth: true, notAdmin: true }} />} />
      <Route path="/google-analytics/:email" element={<Protector name="GoogleAnalytics" elem={GoogleAnalytics} />} />
      <Route path="/google-analytics*" element={<Protector name="GoogleAnalytics" elem={GoogleAnalytics} />} />
      <Route path="/instagram/analytics" element={<Protector name="InstagramAnalytics" elem={Instagram} />} />
      <Route path="/instagram/callback*" element={<Protector name="InstagramCallback" elem={Instagram} />} />
      <Route path="/analytics/:email" element={<Protector name="Home" elem={GoogleAnalytics} />} />
      <Route path="/" element={<Protector name="Home" elem={selectHome(window.location.origin)} meta={selectHomeMeta(window.location.origin)} />} />
      <Route path="*" element={<Protector name="NotFound" elem={NotFound} />} />
    </Routes>
  </Suspense>
);
};

export default TigimRoutes;
