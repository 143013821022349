import type { LocalMetaEmail, LocalStorageFunctions } from './types';

const META_EMAIL: string = 'meta-email';

const getLocalStorageMetaEmail = (fn: LocalStorageFunctions) => (): string => fn.readObject(META_EMAIL);
const setLocalStorageMetaEmail = (fn: LocalStorageFunctions) => (email: string) => fn.writeObject(META_EMAIL, email);

const metaEmail = (fn: LocalStorageFunctions): LocalMetaEmail => ({
  getLocalStorageMetaEmail: getLocalStorageMetaEmail(fn),
  setLocalStorageMetaEmail: setLocalStorageMetaEmail(fn)
});

export default metaEmail;
